import React from 'react';

const PrivacyPolicy = () => {
  const companyInfo = [
    { field: 'Company Name', detail: 'Rapidlend Technologies Pvt. Ltd.' },
    { field: 'Name', detail: 'Dhankosh' },
    { field: 'E-mail id', detail: 'care@Dhankosh.com' },
    { field: 'Phone no', detail: '9811758852' },
    { field: 'Address', detail: 'OAHFEO, 1094P, Sector 46, Gurgaon, Haryana, 122001' },
    { field: 'Website URL', detail: <a href="http://www.Dhankosh.com" target="_blank" rel="noopener noreferrer">www.Dhankosh.com</a> },
  ];
  return (
    <>
      <div className='tcbox'>
        <div className="container">
          <h1 style={{ textAlign: "center" }}>Privacy Policy</h1>
          <p>
            This privacy policy describes how we handle your personal information. We collect, use, and share personal information to help the Rapidlend Technologies Pvt Ltd website ("Dhankosh") work and to keep it safe (details below). In formal terms, Rapidlend Technologies Private Limited known as “Dhankosh”, acting itself, is the "data controller" of your personal information. This policy is effective from 09/04/2022.
          </p>
          <p>
            Dhankosh is a Website (URL: <a href="http://www.Dhankosh.com" target="_blank" rel="noopener noreferrer">www.Dhankosh.com</a>) which delegates vehicle ownership in a new way of financing with flexible usage terms, is owned and operated by Dhankosh Technologies Private Ltd (“Company” or “We” and their connotations). We recognize the privacy concerns of its Users and their guests (hereinafter collectively referred to as the “Users” and/or “you” and their connotations) and hence as a commitment to provide safe and secure experience on our Platform, We have formulated this privacy policy to help Users make an informed decision.
          </p>
          <p>
            This Privacy policy ("Privacy Policy") is meant to help you understand what information we collect, why we collect it, how such information is used and how you can update, manage, export, and delete your information while you access to the Platform (hereinafter referred to as "Services").
          </p>
          <p>
            If you do not agree with this Policy, you are requested, not to use our Services or share herewith your personal data.
          </p>
          <p>
            This Policy constitutes a legal agreement between you, referred as the user of the Platform and the Company referred as the owner of the Platform. The User must be a natural person who has attained the age of majority. If the User has not attained the age of majority, the User must read through this Privacy Policy with his/her legal guardian, and in such a case this Privacy Policy shall be deemed to be a contract between the Company and User's legal guardian and to the extent permissible under applicable laws, enforceable against the User.
          </p>
          <p>
            By visiting or accessing the Platform and voluntarily providing Us with information (personal and/ or non- personal), the User hereby agrees to Our collection and use of provided information in accordance with this Privacy Policy. This Privacy Policy does not apply to third-party platforms/ applications that are connected via links to the Platform.
          </p>
          <p>
            The use of the Platform by the User will be governed by this Privacy Policy as applicable to the Platform together with all policies, notices, guidelines, disclaimers that are published and shared with the User from time to time which are incorporated herein by way of reference including but not limited to Terms of Use and such other terms as may be applicable to the User in his/her capacity as a user of the Platform.
          </p>
          <p>This Privacy Policy shall be enforceable against the User in the same manner as any other written agreement.</p>
          <p>
            The Company may update this Privacy Policy from time to time as per the applicable and/or enforceable laws, rules and regulations or to maintain such compliance needed to be in regard of the same. The User must review the Privacy Policy periodically or at a regular interval for the latest information on our privacy practices prior to sharing of information or use of the Platform hereof.
          </p>

          <h2>Purpose</h2>
          <p>
            The purpose of this Policy is to ensure that the information provided by the User is used, processed, disclosed, and stored in accordance with the applicable laws. The information stored in such manner shall be protected by the Company in accordance with the terms and conditions set out in this Privacy Policy.
          </p>

          <h2>Collection of Information</h2>
          <p>
            In order to provide Services, we require certain user information and may obtain the same through user-provided data or automatic data collection. Our servers are located in Gurgaon, Haryana, India. Therefore, if you choose to provide us with personal information, you are consenting to the transfer and storage of that information on our servers. We collect and store the following personal information:
          </p>
          <p>
            <p>
              <b>a.)</b>We collect such information that may be used to identify you ("Personal Information") including, but not limited to:
              <p>
                <p><b>1.</b> name, e-mail ID, photograph, location, AADHAR card number, PAN card number and other contact information</p>
                <p><b>2.</b> age, gender, and other demographics</p>
                <p><b>3.</b> details of KYC (Know Your Customer) documents, bank account, bank statements, financial documents, income proof, vehicles and vehicle tracking</p>
                <p><b>4.</b >particulars of NACH (National Automated Clearing House) mandate and PDCs (Post Dated Cheque)</p>
              </p>
            </p>
            <p>
              <p>
                <b>b.)</b>We also collect certain information either when you provide while using the Platform or Services and / or automatically as you navigate through the Platform (which may include usage details, IP address, device ID and type, your browser type and language, the operating system used by your device, access times, and information collected through cookies, web beacons and other tracking technologies).
              </p>
            </p>
            <p>
              You hereby agree that we may use such information to do internal research on our Users' demographics and medical history to better understand, protect and serve our Users.
            </p>
            <p>
              <p>
                <b>c.)</b>We also collect such information from your user ID which are associated with any social media account (such as your Facebook and Google account) that users use to sign into the Platform or connect with or use of our Services in consent with. When you sign in with your social media account information, or otherwise connect to your social media account with the Platform, you hereby consent to our collection, storage, and use, in accordance with this Privacy Policy, of the information that you make available to us through the social media interface. This includes, without limitation, any information that you have made public through your social media account, information that the social media service shares with us or information that is disclosed during the sign-up and sign-in processes.
              </p>
            </p>
            <p>
              <p>
                <b>d.)</b>If another User allows us to collect information from his or her device phonebook—and you are one of that User's contacts—we may combine the information we collect from that User's phonebook with other information we have collected about you. We may also obtain information from other sources and combine that with the information we collect through the Platform. The User is required to ensure that the User's contact information and preferences are accurate, complete, and up to date by logging/signing into the User account. We shall also provide the User with the opportunity to request correction of data relating to the User Personal Information if the same is inaccurate or delete the data. Further, we may decline to process requests which We find to be in contrary to the terms laid down under this Privacy Policy, the Terms of Use or any applicable laws, or which require disproportionate technical effort, jeopardize the privacy of others or are extremely impractical. Further, we have the right to take appropriate legal action for inaccuracy of information or any misrepresentation made by you.
              </p>
            </p>
            <p>
              <p>
                <b>e.)</b> We use data collection devices such as "cookies" on certain pages of the Platform to help analyse our web page flow, measure promotional effectiveness, and promote trust and safety. Cookies are small files placed on your hard drive that assist us in providing our Services. We offer certain features that are only available using a cookie. Cookies can also help us provide information that is targeted to your interests.
              </p>
            </p>
            <p>
              <p>
                <b>f.)</b> User shall grant the Company a non-exclusive, transferable, sub-licensable, royalty-free, worldwide license for content that is covered by intellectual property rights, like photos and videos (IP License). This IP License shall end when you delete your IP content or your account unless your content has been shared with others, and they have not deleted it. When you delete IP content, it is deleted in a manner similar to emptying the recycle bin on a computer. However, you understand that removed content may persist in backup copies for a reasonable period (but will not be available to others).
              </p>
            </p>
          </p>
          <p>
            In addition to above, we may collect such information that the User may provide to Us by filling in various forms on the Platform. The Personal Information is collected from the User in a voluntary fashion when the User provide the credentials on the Platform. However, it is clarified that the Personal Information may also contain any chats, texts, videos, audios, files which are used or shared by the User, either alone or in conjunction with any other user of the Platform and any such chats, texts, videos, audios, files may be recorded and/or stored by the Platform.
          </p>
          <p>
            To serve you better and improve our Platform, we may collect the following information:
          </p>
          <ul>
            <p>i. The Company Device ID & call information to enable the Company to receive the phone status and identity;</p>
            <p>ii. Access and read your storage and modify or delete the contents of your storage;</p>
            <p>iii. Access and read your phone status and identity;</p>
            <p>iv. take pictures and videos from your camera;</p>
            <p>v. Track your GPS locations as may be required;</p>
            <p>vi. receive data from Internet;</p>
            <p>vii. close other apps on your device</p>
            <p>viii. read Google service configuration;</p>
            <p>ix. prevent device from sleeping;</p>
            <p>x. run at startup;</p>
            <p>xi. draw over other apps;</p>
            <p>xii. full network access;</p>
            <p>xiii. view network connections and</p>
            <p>xiv. control vibration on your device.</p>
          </ul>
          <h2>Use of Information
          </h2>
          <p>We collect, and stores information provided by users from time to time. This allows us to provide services and features that most likely meet your needs, and:</p>
          <p><b>a.</b> To enable you to avail of our Services, provide leasing facilities and ensure efficient customer care experience and develop new features;</p>
          <p><b>b.</b> To send surveys or marketing communications to inform you of new products or services or other information that may be of your interest;</p>
          <p><b>c.</b> To dispatch transaction-related communications such as welcome letters, billing reminders, and purchase confirmations;</p>
          <p><b>d.</b> To notify the necessary information related to the Platform and Services and your user account on the Platform from time to time;</p>
          <p><b>e.</b> To maintain records and provide you with an efficient, safe and customized experience while using the Platform;</p>
          <p><b>f.</b> To analyse overall trends to helps us improve the Platform and Services;</p>
          <p><b>g.</b> To furnish your information to Service Partners and Providers only to the extent necessary for delivering the relevant services (e.g., scheduling repair and maintenance, providing emergency assistance);</p>
          <p><b>h.</b> Verify your identity and prevent fraud or other unauthorized or illegal activity;</p>
          <p><b>i.</b> To resolve any kind of disputes and to comply with applicable laws, rules, and regulations; and</p>
          <p><b>j.</b> In any other way consistent with this Privacy Policy and our Terms and Conditions.</p>
          <p><b>k.</b> Review and verify the information provided by you in application to ascertain your eligibility for using and participating in the Platform;</p>
          <p><b>l.</b> To create your account if required and to support you with any services related to such account;</p>
          <p><b>m.</b> Assess your credentials including but not limited to educational qualifications, work experience as specified in yours's application for shortlisting or selection for the Platform;</p>
          <p><b>n.</b> To update you about the status of the Platform and to provide you any agreed assistance; </p>
          <p><b>o.</b> To verify whether you are entitled to access and use the Platform, or any of the products and services made available through the Platform;</p>
          <p><b>p.</b> To prevent any kind of abuse, trolling or other acts which may be contrary to law or detrimental to the interest of any other user of the Platform or person;</p>
          <p><b>q.</b> Monitor aggregate metrics such as total number of viewers, visitors, traffic, and demographic patterns;</p>
          <p><b>r.</b> To notify you regarding the account, to troubleshoot problems with the account, to resolve a dispute, to confirm yours's identity to ensure that you are eligible to Use the Platform;
          </p>
          <h2>Additionally,</h2>
          <p><b>s.</b> We reserve the right to use or disclose your Personal Information in response to subpoenas, court orders, warrants, or legal process, or to otherwise establish or exercise our legal rights or defend against legal claims or in the event where you violate or breach an agreement with us. We will use and disclose your Personal Information if we believe that, you will harm the property or rights of the Platform, its owners, or those of the Platform's other users. Finally, we will use or disclose your Personal Information if we believe it is necessary to share information in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person or property, violations of the Platform's other policies, or as otherwise required by law when responding to subpoenas, court orders and other legal processes.</p>
          <p><b>t.</b> If We believe that disclosure is necessary or appropriate to protect our rights, property, or safety, our Users or others, we may in good faith share information with other companies and organizations for the purposes of fraud protection and credit risk reduction. </p>
          <p><b>u.</b> You hereby agree that we may use your Personal Information to improve our marketing and promotional efforts, to analyse site usage, improve the Platform's content and service offerings, and customize the Platform's content, layout, and services. These uses improve the Platform and better tailor it to meet your needs, so as to provide you with an efficient, safe and customized experience while using the Platform.</p>
          <h2>Review & Alteration of Information</h2>
          <p>We strive to accurately record all information provided on the Platform from time to time and as applicable. </p>
          <p><b>a)</b> We encourage you to review, correct, update, and/or change your account information or Personal Information at any time. </p>
          <p><b>b)</b> You may also amend, modify or delete your information at any time on the Platform. However, we may retain certain information as required by law or for reasonable business purposes. </p>
          <p><b>c)</b> You have a right to review, update, correct, and delete your information; subject to our records retention policies and applicable laws, including any statutory retention requirements. </p>
          <p><b>d)</b>  We reserve the right to delete your account at any time if we find that the personal information provided by you is inaccurate, incomplete, or irrelevant for legitimate purposes, or are being processed in a way that infringes any applicable legal requirement. </p>
          <h2>Third-Party Services</h2>
          <p>Our Platform may contain links to other websites. Please note that when you click on one of these links, you provide your consent for entering into another website over which We have no control and for which we will bear no responsibility. Often these websites require you to enter your Personal Information. We encourage you to read the privacy policies of all such websites as their policies may differ from ours. You hereby agree that We shall not be liable for any breach of your privacy of Personal Information or loss incurred by you by using such websites or services.</p>
          <h2>Confidentiality</h2>
          <p>We treat your use of Dhankosh with the utmost respect for your privacy. Except for anything not disclosed above, we keep your information disclosed to the Listed Professional confidential, keeping only non-decimated "file" copies for reference in case of legal dispute. We will use commercially reasonable efforts to protect and secure the same. However, we shall not be liable for any damage/loss/ exposure caused to the information collected by us.</p>
          <h2>Compliance with Data Protection Regulations</h2>
          <p>The information we obtain from or about you may be processed and stored in India and our other various servers located across the globe, which may provide for different data protection rules than the country in which you reside. We comply with generally accepted industry standard regulations regarding the collection, use, and retention of data. By using the Platform and/or Services, you consent to the collection, transfer, use, storage and disclosure of your information as described in this Privacy Policy, including to the transfer of your information outside of your country of residence.</p>
          <h2>Measures taken to Protect Your Information</h2>
          <p>We have implemented appropriate physical, electronic, and managerial procedures to safeguard and help prevent unauthorized access to your information and to maintain data security. Further, we secure the sensitive personal information in accordance with the standards equivalent to ISO 27001. These safeguards consider the sensitivity of the information that We collect, process and store and the current state of technology. We store and process your information on reputed web service cloud servers. We impose contractual obligation if in any case We outsource the task of data processing. We follow generally accepted industry standards to protect your information submitted to us, both during transmission and once received, including the use of vault and tokenization services from third-party service providers.</p>
          <p>While We strive to use commercially acceptable means to protect your information, We cannot guarantee its absolute security and thereby usage in a manner that is inconsistent with this Privacy Policy.</p>
          <p>We neither accepts nor assumes any liability or responsibility for disclosure of your information due to errors in transmission, unauthorized third-party access, or other causes beyond our control. We do not, under any circumstances, represent that our platform is completely void of malware including but not limited to viruses, spywares, cancel bots, Trojans, time bombs and any such application, which may be placed on our platform without our knowledge. However, We shall take all the necessary and reasonable steps to ensure that our platform is free from the above-mentioned malware.</p>
          <p>You also play a key role in keeping your information secure. Using unsecured Wi-Fi or other unprotected networks to submit messages through the Platform is never recommended. You should not share your username, password, or other security information for your account or the device through which you access our Platform and/or Services (as the case may be) with anyone. If We receive any instructions through any account or device or any other medium bearing your information, we will consider that you have authorized the instructions. Please remember that if you post any of your Personal Information in public areas of the Platform such as in online forums or chat rooms, or in the Platform's searchable database, or share such information with anybody else, such information may be collected and used by others over whom we have no control. We are not responsible for the use made by third parties of information you post or otherwise make available in public areas of Platform or share with any other person.</p>
          <h2>In the Event of Change of Control</h2>
          <p>In the event of the change of control of the Company due to the merger with a third party, acquisition, bankruptcy or other change of control, we reserve the right to transfer or assign the information we have collected from our users as part of such merger, acquisition, sale, or other change of control. In the unlikely event of our bankruptcy, insolvency, reorganization, receivership, or assignment for the benefit of creditors, or the application of laws or equitable principles affecting creditors' rights generally, we may not be able to control how your Personal Information is treated, transferred, or used.</p>
          <h2>Changes to this Privacy Policy</h2>
          <p>We reserve the right to update, change or modify this Privacy Policy at any time to reflect any (including but not limited to) changes in the law, the data collection and practices, the features of the Platform or Services, or advances in technology. The amendment to this Privacy Policy shall come to effect from the time of such update, change or modification and the same will be published here. You are requested to review the Privacy Policy carefully from time to time. Use of information collected is subject to the Privacy Policy in effect at the time such information is used. The changes to this Privacy Policy shall be treated as read, recognized, understood, consented and accepted if you continue to use the Platform or Services post such changes. Governing Laws and Dispute Resolution This Privacy Policy shall be governed by and construed in accordance with the Arbitration and Conciliation Act 1996, or such similar laws of India which are not in conflict with each other. Such shall be subjected to the exclusive jurisdiction of the competent courts of Bangalore, Karnataka, India.</p>
          <h2>Acceptance</h2>
          <p>You hereby agree that Dhankosh and its affiliate(s) may contact you either electronically or through phone, to understand your interest in the selected products and services and to fulfil your demand. You also hereby agree that Dhankosh reserves the right to make your details available to any of our authorized members/ agents or partners and you may be contacted by them for information and for sales through email, telephone and/or text message. You agree to receive promotional materials and/or special offers from Dhankosh through calls, email or text message. If you are in any doubt as to the suitability, adequacy or appropriateness of any product or service referred to on this Website, we suggest that you seek independent professional advice before you obtain any product or service via this Website. As a User of this Website, you have agreed to the Terms & Conditions that Dhankosh has provided hereunder or anywhere else in this Website, including but not limited to disclaimers on this Website. You are advised to read and understand the said Terms & Conditions and in case you do not wish to agree to these Terms & Conditions, please do not use the Website.</p>
          <h2>CONSENT IN RELATION TO ACCESS TO CREDIT INFORMATION THROUGH EXPERIAN/CRIF/CIBIL</h2>
          <p>This End User Agreement (the “Agreement”) is made between you (the “User” or “You”) and Rapidlend Technologies Private Limited ( known as “Dhankosh”, a private limited company incorporated under the Companies Act, 2013 and having its registered office at 554, Mandakini Enclave, Alaknanda, New Delhi 110019, India and a branch (amongst others) at 1094P, OAHFEO, Sector 46, Gurgaon, 122001 (“CLIENT”, “Us” or “We” or “Dhankosh”, which term shall include its successors and permitted assigns). The User and CLIENT shall be collectively referred to as the “Parties” and individually as a “Party”. You hereby consent to Dhankosh being appointed as your authorised representative to receive your Credit Information on an ongoing basis until the eligibility (“End Use Purpose”) is satisfied or expiry of 6 months from date the consent is collected; whichever is earlier.</p>
          <p><b>BY EXECUTING THIS AGREEMENT / CONSENT FORM, YOU ARE EXPRESSLY AGREEING TO ACCESS THE EXPERIAN /CRIF/CIBIL CREDIT INFORMATION REPORT AND CREDIT SCORE, AGGREGATE SCORES, INFERENCES, REFERENCES AND DETAILS (AS DEFINED BELOW)(TOGETHER REFERRED AS “CREDIT INFORMATION”). YOU HEREBY ALSO IRREVOCABLY AND UNCONDITIONALLY CONSENT TO SUCH CREDIT INFORMATION BEING PROVIDED BY EXPERIAN/CRIF/CIBIL TO YOU AND CLIENT BY USING EXPERIAN/CRIF/CIBIL TOOLS, ALGORITHMS AND DEVICES AND YOU HEREBY AGREE, ACKNOWLEDGE AND ACCEPT THE TERMS AND CONDITIONS SET FORTH HEREIN.</b></p>
          <h2>Terms and Conditions:</h2>
          <h2>Information Collection, Use, Confidentiality, No-Disclosure and Data Purging</h2>
          <p>CLIENT shall access your Credit Information as your authorized representative and CLIENT shall use the Credit Information for limited End Use Purpose consisting of and in relation to the services proposed to be availed by you from CLIENT. We shall not aggregate, retain, store, copy, reproduce, republish, upload, post, transmit, sell or rent the Credit Information to any other person and the same cannot be copied or reproduced other than as agreed herein and in furtherance to applicable law including the CICRA.</p>
          <p>The Parties agree to protect and keep confidential the Credit Information both online and offline.</p>
          <p>The Credit Information shared by you, or received on by us on your behalf shall be destroyed, purged, erased immediately upon the completion of the transaction/ End Use Purpose for which the Credit Information report was procured, this period not being longer than 6 months.</p>
          <h2>Governing Law and Jurisdiction</h2>
          <p>The relationship between you and CLIENT shall be governed by laws of India and all claims or disputes arising there from shall be subject to the exclusive jurisdiction of the courts of Gurgaon.</p>
          <h2>Definitions:</h2>
          <p>Capitalised terms used herein but not defined above shall have the following meanings: “Business Day” means a day (other than a public holiday) on which banks are open for general business in Gurgaon.</p>
          <p>“Credit Information Report” means the credit information / scores/ aggregates / variables / inferences or reports which shall be generated by Experian/CRiF/CIBIL.;</p>
          <p>“Credit Score” means the score which shall be mentioned on the Credit Information Report which shall be computed by Experian/CRiF/CIBIL.</p>
          <p>“CICRA” shall mean the Credit Information Companies (Regulation) Act, 2005 read with the Credit Information Companies Rules, 2006 and the Credit Information Companies Regulations, 2006, and shall include any other rules and regulations prescribed thereunder.</p>
          <p>You undertake and acknowledge that, for the purpose of assessing your credit worthiness, Dhankosh and its affiliate(s) are authorized to pull from CIBIL and/or any other agency authorized by RBI,</p>
          <p><b>I)</b> data of the applicant(s), directors, group companies, and any other individuals who are involved in the said business, on behalf of which this loan/lease application is made;</p>
          <p><b>II)</b> CIBIL and/or any other agency so authorized may use, process the said information and data disclosed by Dhankosh and it's affiliate(s) in the manner as deemed fit by them;</p>
          <p><b>III)</b> CIBIL and/or any other agency so authorized may furnish for consideration, the processed information and data or products thereof prepared by them, to banks/financial institutions and other credit grantors or registered users, as may be specified by the RBI in this behalf;</p>
          <p><b>IV)</b> For the purpose of verification of my/our personal data, Dhankosh and it's affiliate(s) are authorized to pull from Central KYC Records Registry, Know Your Customer' data of the applicant(s), directors, group companies, and any other individuals who are involved in the said business, on behalf of which this loan/lease application is made and</p>
          <p><b>V)</b> For the purpose of assessing our credit worthiness, Dhankosh and it's affiliate(s) are authorized to fetch Goods and Service Tax returns/data, of the applicant(s), group companies, on behalf of which this loan application is made.
            As a website user, you agree and provide consent to receive all communications at the mobile number provided, even if this mobile number is registered under DND/NCPR list under TRAI regulations. And for that purpose, you further authorise Dhankosh to share/disclose the information to any third party service provider or any affiliates, group companies, their authorised agents or third party service providers.
          </p>
          <p>(or)</p>
          <p>Notwithstanding User's registration with National Do Not Call Registry (In Fully or Partly blocked category under National Customer Preference Register set up under Telecom Regulatory Authority of India), you hereby expresses your interest and accord its wilful consent to receive communication (including commercial communication) in relation to Dhankosh. User further confirms that any communication, as mentioned herein above, shall not be construed as Unsolicited Commercial Communication under the TRAI guidelines and User has specifically opted to receive communication in this regard on the telephone number provided by the User.</p>
          <h2>Exclusion</h2>
          <p>This Privacy Policy does not apply to any information other than such information collected by the Company through the Platform. This Privacy Policy shall not apply to any unsolicited information the User provide Us through this Platform or through any other means. This includes, but is not limited to, information posted to any public areas of the Platform. All such unsolicited information shall be deemed to be non-confidential, and the Company shall be free to use, process or disclose such unsolicited information without any limitations. We make no representation that the content contained on the Platform is appropriate. The Company's responsibility with respect to such information shall be as per the applicable laws. If the User intend to use or access the Platform from outside territory as may be defined by the Company from time to time, the User may do so at the User's own risk and is responsible for compliance with the laws of such jurisdiction.</p>
          <h2>Severability</h2>
          <p>We have taken every effort to ensure that this Privacy Policy adheres with the applicable laws, rules and regulations. The invalidity or unenforceability of any part of this Policy shall not prejudice or affect the validity or enforceability of the remainder of this Privacy Policy. This Privacy Policy does not apply to any information other than the information collected by the Company through the Platform.</p>
          <h2>Waiver</h2>
          <p>Any rights or remedies under this Privacy Policy may be waived, only if provided in writing. Delay in exercising or non-exercise of any such right or remedy does not constitute a waiver of that right or remedy, or any other right or remedy.</p>
          <h2>Choice/Opt-Out/Deletion/Correction</h2>
          <p>Company provides all its Users with the opportunity to opt-out of receiving non-essential communications from Us or on behalf of Our partners after setting up an account. If the User wants to remove the User's contact information from all Our lists and newsletters, please write to us at care@Dhankosh.com with “unsubscribe” as the subject or any button provided on the Platform, as the case maybe.</p>
          <p><b>a)</b> If the User wishes to have the information or data that the User have provided to the Company deleted, the User can always do so by sending a request to us on the above-mentioned e-mail id.</p>
          <p><b>b)</b> The User may note that correction or deletion of certain information or data may lead to cancellation of the registration with the Platform or access to certain features of the Platform the User also agrees and acknowledges that certain data or information cannot be corrected or deleted or prohibited to be deleted as required under any applicable law, law enforcement requests or under any judicial proceedings.</p>
          <p><b>c)</b> Any information that is sought to be deleted, whether upon deactivation of the User's account on the Platform or otherwise, shall be deleted from the user interface of the Platform but may be retained on the servers of the Company for such period as may be required under applicable laws, Terms of Use or as per the policy of the Company or any third-party vendor engaged by the Company.</p>
          <p><b>d)</b> The User acknowledges and agrees that the Company and/or any third-party vendor engaged and authorised by the Company shall be entitled to retain such information to the extent required under this clause and the Company disclaims any liability or obligation not specifically agreed hereunder with respect to such information. The User also agrees that any aggregated information or data based on the analysis or benchmarking activity undertaken by the Company, either manually or through related algorithms, shall be the sole property of the Company and the Company shall neither have the obligation to delete it or provide such aggregated information or data to the User.</p>
          <h2>Discrepancies and Grievances with the Company</h2>
          <p>In case of any discrepancy or grievance with respect to all or any information shared with the Company, please feel free to contact at the grievance officer.</p>
          <p>We assure you that we shall ensure implementation of this Privacy Policy and shall make the Policy available to individuals and redress the grievances of the user expeditiously within 2 (two) months from the date of receipt of grievance.</p>
          <p>The User's grievances, questions regarding this Privacy Policy should be directed to Our grievance officer mentioned below: </p>
          <p><b>Name of the Grievance Office : </b>Mr. Rajat Joshi</p>
          <p><b>Designation:</b> Chief Grievance Officer</p>
          <p><b>E-mail: </b>care@dhankosh.com</p>
          <h2>Contact Details</h2>
          <p>If you have any questions about this Privacy Policy, please contact us by email or postal mail on the following address:</p>
          <div className="company-info">
            <div className="info-grid">
              {companyInfo.map((item, index) => (
                <React.Fragment key={index}>
                  <div className="info-field"><p><b>{item.field}</b></p></div>
                  <div className="info-detail"> <p>{item.detail}</p></div>
                </React.Fragment>
              ))}
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
