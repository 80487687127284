import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AboutUs from './components/About-us/AboutUs';
import Homepage from './components/pages/Homepage';
import Header from './components/homepage/Header';
import Footer from './components/homepage/Footer';
import TermsAndCondition from './components/Termsandcondition/TermsAndCondition';
import ContactUs from './components/Contact-us/ContactUs';
import "./App.css"
import PartnerWithUs from './components/homepage/PartnerWithUs';
import PrivacyPolicy from './components/Privacy-policy/PrivacyPolicy';

const App = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path='/' element={<Homepage />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/terms-conditions' element={<TermsAndCondition />} />
        <Route path='/contact-us' element={ <ContactUs />} />
        <Route path='/partner-with-us' element={ <PartnerWithUs />} />
        <Route path='/privacy-policy' element={ <PrivacyPolicy />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
